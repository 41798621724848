import {css} from 'styled-components';
import constants from '../../constants';

export default css`
  --contentMaxWidth: 1200px;
  --contentMinHeight: 840px;
  --topNavBarHeight: 64px;
  --basePadding: 15px 20px;
  --primaryColor: ${constants.THEME_COLOR};

  min-height: 100vh;
`;
