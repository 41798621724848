// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-generators-admin-resource-index-js": () => import("./../../../src/Generators/AdminResource/index.js" /* webpackChunkName: "component---src-generators-admin-resource-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/Templates/About/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-admin-coupon-template-index-js": () => import("./../../../src/Templates/AdminCouponTemplate/index.js" /* webpackChunkName: "component---src-templates-admin-coupon-template-index-js" */),
  "component---src-templates-admin-event-index-js": () => import("./../../../src/Templates/AdminEvent/index.js" /* webpackChunkName: "component---src-templates-admin-event-index-js" */),
  "component---src-templates-admin-index-js": () => import("./../../../src/Templates/Admin/index.js" /* webpackChunkName: "component---src-templates-admin-index-js" */),
  "component---src-templates-admin-order-admin-order-detail-js": () => import("./../../../src/Templates/AdminOrder/AdminOrderDetail.js" /* webpackChunkName: "component---src-templates-admin-order-admin-order-detail-js" */),
  "component---src-templates-admin-order-index-js": () => import("./../../../src/Templates/AdminOrder/index.js" /* webpackChunkName: "component---src-templates-admin-order-index-js" */),
  "component---src-templates-admin-store-event-setting-index-js": () => import("./../../../src/Templates/AdminStoreEventSetting/index.js" /* webpackChunkName: "component---src-templates-admin-store-event-setting-index-js" */),
  "component---src-templates-admin-user-point-index-js": () => import("./../../../src/Templates/AdminUserPoint/index.js" /* webpackChunkName: "component---src-templates-admin-user-point-index-js" */),
  "component---src-templates-admin-user-stamp-index-js": () => import("./../../../src/Templates/AdminUserStamp/index.js" /* webpackChunkName: "component---src-templates-admin-user-stamp-index-js" */),
  "component---src-templates-article-detail-index-js": () => import("./../../../src/Templates/ArticleDetail/index.js" /* webpackChunkName: "component---src-templates-article-detail-index-js" */),
  "component---src-templates-article-list-index-js": () => import("./../../../src/Templates/ArticleList/index.js" /* webpackChunkName: "component---src-templates-article-list-index-js" */),
  "component---src-templates-cart-index-js": () => import("./../../../src/Templates/Cart/index.js" /* webpackChunkName: "component---src-templates-cart-index-js" */),
  "component---src-templates-checkout-payment-index-js": () => import("./../../../src/Templates/CheckoutPayment/index.js" /* webpackChunkName: "component---src-templates-checkout-payment-index-js" */),
  "component---src-templates-coupon-list-store-coupon-page-js": () => import("./../../../src/Templates/CouponList/StoreCouponPage.js" /* webpackChunkName: "component---src-templates-coupon-list-store-coupon-page-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-mobile-payment-index-js": () => import("./../../../src/Templates/MobilePayment/index.js" /* webpackChunkName: "component---src-templates-mobile-payment-index-js" */),
  "component---src-templates-mobile-payment-mobile-redirect-js": () => import("./../../../src/Templates/MobilePayment/MobileRedirect.js" /* webpackChunkName: "component---src-templates-mobile-payment-mobile-redirect-js" */),
  "component---src-templates-order-detail-index-js": () => import("./../../../src/Templates/OrderDetail/index.js" /* webpackChunkName: "component---src-templates-order-detail-index-js" */),
  "component---src-templates-pay-index-js": () => import("./../../../src/Templates/Pay/index.js" /* webpackChunkName: "component---src-templates-pay-index-js" */),
  "component---src-templates-pos-edit-product-stock-index-js": () => import("./../../../src/Templates/PosEditProductStock/index.js" /* webpackChunkName: "component---src-templates-pos-edit-product-stock-index-js" */),
  "component---src-templates-pos-edit-station-index-js": () => import("./../../../src/Templates/PosEditStation/index.js" /* webpackChunkName: "component---src-templates-pos-edit-station-index-js" */),
  "component---src-templates-pos-edit-station-products-index-js": () => import("./../../../src/Templates/PosEditStationProducts/index.js" /* webpackChunkName: "component---src-templates-pos-edit-station-products-index-js" */),
  "component---src-templates-pos-edit-sticker-products-index-js": () => import("./../../../src/Templates/PosEditStickerProducts/index.js" /* webpackChunkName: "component---src-templates-pos-edit-sticker-products-index-js" */),
  "component---src-templates-product-detail-index-js": () => import("./../../../src/Templates/ProductDetail/index.js" /* webpackChunkName: "component---src-templates-product-detail-index-js" */),
  "component---src-templates-product-list-index-js": () => import("./../../../src/Templates/ProductList/index.js" /* webpackChunkName: "component---src-templates-product-list-index-js" */),
  "component---src-templates-store-list-index-js": () => import("./../../../src/Templates/StoreList/index.js" /* webpackChunkName: "component---src-templates-store-list-index-js" */),
  "component---src-templates-table-index-js": () => import("./../../../src/Templates/Table/index.js" /* webpackChunkName: "component---src-templates-table-index-js" */)
}

