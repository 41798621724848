import React from 'react';
import {Button, Input, Modal, Table, message} from 'antd';
import {getNewOutlet, getOutlet, useOutlet} from 'reconnect.js';
import * as Jstorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';

getNewOutlet('admin-search-product-modal', null, {autoDelete: false});

export function showAdminSearchProductModal(data) {
  getOutlet('admin-search-product-modal').update(data);
}

const fetchRecords = async (
  keyword,
  columns,
  paging = {offset: 0, limit: 4},
) => {
  try {
    AppActions.setLoading(true);
    return Jstorage.fetchDocuments(
      'product',
      {
        $or: columns.map((column) => ({
          [column.key]: {$regex: keyword},
        })),
      },
      null,
      paging,
    );
  } catch (err) {
    message.error('發生錯誤。');
  } finally {
    AppActions.setLoading(false);
  }
};

export default function AdminSearchProductModal(props) {
  const [data, setData] = useOutlet('admin-search-product-modal');
  const [keyword, setKeyword] = React.useState(null);
  const [records, setRecords] = React.useState(null);
  // const [_selectedRows, _setSelectedRows] = React.useState([]);
  const [pagination, setPagination] = React.useState({
    total: 0,
    pageSize: 4,
  });

  const columns = [
    {
      key: 'name',
      title: '名稱',
      dataIndex: 'name',
    },
    {
      key: 'note',
      title: '備註',
      dataIndex: 'note',
    },
    {
      key: 'price',
      title: '價錢',
      dataIndex: 'price',
    },
    {
      key: '',
      title: '',
      dataIndex: '',
      render: (_, item) => {
        return (
          <Button
            onClick={() => {
              data.onChange(item);
              onClose();
            }}>
            選擇
          </Button>
        );
      },
    },
  ];

  function onClose() {
    setKeyword(null);
    setRecords(null);
    // _setSelectedRows([]);
    setData(null);
  }

  return (
    <Modal
      visible={!!data}
      onCancel={onClose}
      footer={null}
      width={1000}
      closable={false}
      maskClosable={true}
      destroyOnClose={true}>
      <div>
        <Input.Search
          style={{marginBottom: 10}}
          placeholder="請輸入關鍵字"
          allowClear
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          onSearch={async () => {
            let {total, results} = await fetchRecords(keyword, columns, {
              offset: 0,
              limit: pagination.pageSize,
            });
            setRecords(results);
            setPagination({...pagination, total});
          }}
        />

        <Table
          style={{marginBottom: 10}}
          scroll={{x: 500, y: 300}}
          pagination={{
            ...pagination,
            onChange: async (page, pageSize) => {
              let {total, results} = await fetchRecords(keyword, columns, {
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
              setRecords(results);
              setPagination({...pagination, total});
            },
          }}
          /*
          rowKey={(record) => record.owner}
          rowSelection={{
            type: 'radio',
            onChange: (selectedRowKeys, selectedRows) => {
              _setSelectedRows(selectedRows);
            },
          }}
          */
          columns={columns}
          dataSource={records}
        />
      </div>
    </Modal>
  );
}
