import React from 'react';
import styled from 'styled-components';
import {useSelectedStore, setSelectedStore} from '../SelectStoreModal';
import {
  useSelectedOrderType,
  unsetSelectedData,
  showSelectOrderTypeModal,
} from '../SelectOrderTypeModal';
import Theme from '../../Theme';

export default function OrderTypeHeader(props) {
  const selectedStore = useSelectedStore();
  const selectedOrderType = useSelectedOrderType();
  const isTableOrder = !!selectedOrderType?.table;

  async function changeOrderType() {
    if (isTableOrder) {
      if (window.confirm('注意: 將會清除桌邊點餐設定, 確定要繼續嗎?')) {
        setSelectedStore(null);
        unsetSelectedData();
        showSelectOrderTypeModal(true);
      }
      return;
    }

    showSelectOrderTypeModal(true);
  }

  let orderTypeLabel = '立即取餐';

  if (isTableOrder) {
    orderTypeLabel = '桌邊點餐';
  } else if (typeof selectedOrderType?.time === 'string') {
    orderTypeLabel = '預約點餐';
  }

  return (
    <Wrapper
      onClick={() => {
        changeOrderType();
      }}>
      {selectedStore ? (
        <>
          <div>{orderTypeLabel}</div>
          <div
            style={{
              width: 1,
              height: 16,
              backgroundColor: '#fff',
              margin: '0 8px',
            }}
          />
          <div>{selectedStore.name || '---'}</div>
        </>
      ) : (
        <div>選擇門市</div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  padding: 6px 24px;
  min-width: 40px;
  min-height: 40px;
  /* border: 1px solid ${Theme.colors.main}; */
  background-color: ${Theme.colors.main};
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
`;
