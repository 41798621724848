import React from 'react';
import {Steps} from 'antd';
import styled from 'styled-components';
import useIsInApp from 'rev.sdk.js/Hooks/useIsInApp';

export default function CheckoutLayout(props) {
  const isInApp = useIsInApp();
  const {style = {}} = props;
  const route = props.location.pathname;

  function renderCustomSection(sectionId) {
    const subRoute = route.split('/')[2];
    let current = 0;

    if (subRoute === 'payment') {
      current = 1;
    } else if (subRoute === 'review') {
      current = 2;
    }

    if (sectionId === 'B') {
      return (
        <Steps
          direction="horizontal"
          type="navigation"
          responsive={false}
          current={current}
          style={{marginBottom: 20, paddingTop: 16}}>
          <Steps.Step title="購物車" />
          <Steps.Step title="付款" />
          <Steps.Step title="完成" />
        </Steps>
      );
    }

    return null;
  }

  return (
    <Wrapper id="rev-checkout-layout" isInApp={isInApp} style={{...style}}>
      {renderCustomSection('A')}

      <div className="checkout-steps">
        <div className="content">{renderCustomSection('B')}</div>
      </div>

      <div className="content">
        {props.children}

        {renderCustomSection('J')}
      </div>

      {renderCustomSection('K')}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-top: ${({isInApp}) => (isInApp ? 0 : 'var(--topNavBarHeight)')};
  background-color: #fff;

  & > .checkout-steps {
    background-color: #ece9e8;

    & > .content {
      max-width: var(--contentMaxWidth);
      margin: 0 auto;

      & .ant-steps-item > .ant-steps-item-container {
        padding: 12px 20px;
      }
      & .ant-steps-item::before {
        display: none;
      }
      & .ant-steps-item::after {
        margin-top: -6px;
      }
      & .ant-steps-item.ant-steps-item-active > .ant-steps-item-container {
        background-color: #fff;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
      }
    }
  }

  & > .content {
    max-width: var(--contentMaxWidth);
    min-height: 100vh;
    margin: 0 auto;
    padding-bottom: 150px;
  }

  @media only screen and (max-width: 480px) {
    &#rev-checkout-layout .ant-steps-navigation .ant-steps-item::after {
      display: none;
    }
  }
`;
