//admin user permission
export function isRoot(user) {
  return user.username === '6273698ec783f5262ee756e0'; // laya
}

export function checkFunction(user, key) {
  return user?.permission?.[key];
}

export const permissions = {
  setting_read: 'setting_read',
  product_read: 'product_read',
  order_read: 'order_read',
  article_read: 'article_read',
  push_notification_read: 'push_notification_read',
  user_read: 'user_read',
  store_read: 'store_read',
  store_write: 'store_write',
  store_price_delta_read: 'store_price_delta_read',
};

function addSlash(pathname) {
  return pathname[pathname.length - 1] !== '/' ? pathname + '/' : pathname;
}

export function readable({path, user}) {
  path = addSlash(path);
  if (!user) {
    return false;
  }

  switch (path) {
    case '/admin/admin_users/':
      return isRoot(user);
    case '/admin/site/':
    case '/admin/events/':
    case '/admin/legacy_event/':
    case '/admin/article_category/':
    case '/admin/config/':
    case '/admin/hotsearch/':
    case '/admin/landing/':
    case '/admin/evt_current/':
    case '/admin/meal/':
    case '/admin/navbar_config/':
    case '/admin/product_attribute/':
    case '/admin/product_category/':
    case '/admin/promotion_category/':
    case '/admin/variant_group/':
    case '/admin/scratch_card_event':
    case '/admin/draw_lots_event':
    case '/admin/coupon_distribution_event':
      return checkFunction(user, permissions.setting_read);
    case '/admin/products/':
      return checkFunction(user, permissions.product_read);
    case '/admin/store_orders/':
    case '/admin/store_order/':
      return checkFunction(user, permissions.order_read);
    case '/admin/articles/':
      return checkFunction(user, permissions.article_read);
    case '/admin/push_notifications/':
      return checkFunction(user, permissions.push_notification_read);
    case '/admin/stores/':
    case '/admin/store_secrets/':
    case '/admin/store_promotions/':
    case '/admin/pos_stock_records/':
    case '/admin/store_pos_configs/':
    case '/admin/store_groups/':
      return checkFunction(user, permissions.store_read);
    case '/admin/users/':
    case '/admin/user_points/':
    case '/admin/point_exchange_histories/':
    case '/admin/stamp/':
    case '/admin/coupon_templates/':
    case '/admin/coupon_template/':
    case '/admin/coupon_instances/':
      return checkFunction(user, permissions.user_read);
    default:
      return true;
  }
  return true;
}

export function writable({path, user}) {
  path = addSlash(path);
  if (!user) {
    return false;
  }

  //TODO:
  /*
    文章編輯
    推波發送
    使用者點數刪除
  */

  switch (path) {
    case '/admin/admin_users/':
      return isRoot(user);
    case '/admin/stores/':
    case '/admin/store_secrets/':
    case '/admin/store_promotions/':
    case '/admin/pos_stock_records/':
    case '/admin/store_pos_configs/':
      return checkFunction(user, permissions.store_write);
    case '/admin/site/':
    case '/admin/events':
    case '/admin/legacy_event':
    case '/admin/article_category/':
    case '/admin/config/':
    case '/admin/hotsearch/':
    case '/admin/landing/':
    case '/admin/evt_current/':
    case '/admin/meal/':
    case '/admin/navbar_config/':
    case '/admin/product_attribute/':
    case '/admin/product_category/':
    case '/admin/promotion_category/':
    case '/admin/variant_group/':
    case '/admin/scratch_card_event':
    case '/admin/draw_lots_event':
    case '/admin/coupon_distribution_event':
    case '/admin/products/':
    case '/admin/store_orders/':
    case '/admin/store_order/':
    case '/admin/articles/':
    case '/admin/push_notifications/':
    case '/admin/users/':
    case '/admin/user_points/':
    case '/admin/point_exchange_histories/':
    case '/admin/store_groups/':
    case '/admin/stamp/':
    case '/admin/coupon_templates/':
    case '/admin/coupon_template/':
    case '/admin/coupon_instances/':
    default:
      return true;
  }
}
