import React from 'react';
import styled from 'styled-components';
import {Modal, Button, message} from 'antd';
import {useOutlet} from 'reconnect.js';
import {useSelectedStore, showSelectStoreModal} from '../SelectStoreModal';
import {ChevronRight} from '@styled-icons/boxicons-regular/ChevronRight';
import * as AppActions from '../../AppActions';
import * as LocalCartUtil from '../../Utils/LocalCartUtil';
import * as StoreUtil from '../../Utils/StoreUtil';
import * as LayaCartUtil from '../../Utils/LayaCartUtil';
import SelectOrderTimeModal from '../SelectOrderTimeModal';
import Constants from '../../constants';
import Theme from '../../Theme';
import {
  useSelectedOrderType,
  unsetSelectedData,
  setSelectedTable,
  showSelectOrderTypeModal,
  onOrderTimeSelected,
} from './helper';

export default function SelectOrderTypeModal(props) {
  const [innerState, setInnerState] = useOutlet('SelectOrderType');
  const selectedStore = useSelectedStore();
  const {forceVisible} = props;
  const {visible, data} = innerState;
  const [
    showSelectOrderTimeModal,
    setShowSelectOrderTimeModal,
  ] = React.useState(false);
  const [cartInvalidReason, setCartInvalidReason] = React.useState('');

  React.useEffect(() => {
    if (forceVisible) {
      showSelectOrderTypeModal(true);
    }
  }, [forceVisible]);

  function setInnerField(field, value) {
    const nextData = {
      ...data,
      [field]: value,
    };
    setInnerState({
      ...innerState,
      data: nextData,
    });
    LocalCartUtil.setOrderType(nextData);
  }

  function ensureSelection() {
    if (!selectedStore) {
      message.info('請選擇分店');
      return false;
    }
    return true;
  }

  function closeModal() {
    if (!ensureSelection()) {
      return;
    }

    setInnerState({...innerState, visible: false});
  }

  async function startToOrder() {
    if (!ensureSelection()) {
      return;
    }

    // hit cart api to check store status valid
    await LayaCartUtil.refreshCart();
    const [validCart, invalidReason] = LayaCartUtil.validateCart();
    if (!validCart) {
      setCartInvalidReason(invalidReason);
      return message.warning(invalidReason);
    }

    setInnerState({...innerState, visible: false});
    AppActions.navigate(`/products/?showMenu=true`);
  }

  const disableImmediateOrder =
    selectedStore &&
    !StoreUtil.isStoreOpen({store: selectedStore, dt: new Date()}) &&
    !data?.time;

  return (
    <>
      <Modal
        visible={visible || forceVisible}
        onCancel={() => closeModal()}
        bodyStyle={{padding: 0, borderRadius: 8}}
        style={{padding: 0, borderRadius: 8}}
        footer={null}
        destroyOnClose>
        <Wrapper>
          <h2>選擇門市</h2>

          <Button
            style={{width: '100%'}}
            size="large"
            onClick={() => {
              showSelectStoreModal(true);
              setCartInvalidReason('');
            }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div style={{flex: 1, textAlign: 'left'}}>
                {selectedStore?.name || '尚未選擇'}
              </div>
              <ChevronRight size={16} />
            </div>
          </Button>

          <h2>選擇取餐方式/時間</h2>

          <div className="take-away-info-bar">
            <button
              className={
                'immediate' + (data?.time === null ? ' selected ' : '')
              }
              onClick={() => {
                setInnerField('time', null);
                setCartInvalidReason('');
              }}
              src="/images/select-immediate.png"
              hoverSrc="/images/select-immediate-hover.png"
              disabled={!selectedStore}></button>
            <button
              className={
                'reserve' +
                (data?.time !== null && data?.time !== undefined
                  ? ' selected '
                  : '')
              }
              onClick={() => {
                setShowSelectOrderTimeModal(true);
                setCartInvalidReason('');
              }}
              src="/images/select-reserve.png"
              srcHover="/images/select-reserve-hover.png"
              disabled={!selectedStore}>
              <div className="after">
                {typeof data?.time === 'string' ? data.time.slice(0, -3) : ''}
              </div>
            </button>
          </div>

          {disableImmediateOrder && (
            <div style={{marginTop: 40, color: 'red', textAlign: 'center'}}>
              現在非店家供應時間, 請選擇其他店家
            </div>
          )}

          {cartInvalidReason && (
            <div style={{marginTop: 40, color: 'red', textAlign: 'center'}}>
              {cartInvalidReason}
            </div>
          )}

          <Button
            type="primary"
            size="large"
            style={{width: '100%', marginTop: 40}}
            disabled={disableImmediateOrder || cartInvalidReason}
            onClick={() => startToOrder()}>
            開始點餐
          </Button>
        </Wrapper>
      </Modal>

      <SelectOrderTimeModal
        visible={showSelectOrderTimeModal}
        setVisible={setShowSelectOrderTimeModal}
        store={selectedStore}
        onResult={({dt, time}) => {
          onOrderTimeSelected(setInnerField, {dt, time});
        }}
      />
    </>
  );
}

const Wrapper = styled.div`
  padding: 30px;

  & > h2 {
    font-size: 1.2rem;
    margin: 20px 0;
  }

  & .order-type-bar {
    margin: 40px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    & > * {
      transition: all 200ms;
      border-radius: 8px;
      border: 1px solid #ccc;
      width: 144px;
      height: 144px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        box-shadow: 0 3px 6px transparent;
      }
      & > *:last-child {
        margin-top: 10px;
        font-size: 1rem;
      }
    }
  }

  & .take-away-info-bar {
    display: flex;
    align-items: center;
    & > .gutter {
      flex: 1;
    }
    & > button {
      border: 0;
      outline: none;
      margin-right: 6px;
      flex: 1;
      background-color: transparent;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 60px;
      cursor: pointer;
      position: relative;

      &:active {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
      }

      &.immediate {
        background-image: url('/images/select-immediate.png');
      }
      &.immediate.selected {
        background-image: url('/images/select-immediate-hover.png');
      }

      &.reserve {
        background-image: url('/images/select-reserve.png');
      }
      &.reserve.selected {
        background-image: url('/images/select-reserve-hover.png');
      }

      & > .after {
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }
`;

export {
  useSelectedOrderType,
  unsetSelectedData,
  setSelectedTable,
  showSelectOrderTypeModal,
};
