const Theme = {
  colors: {
    main: '#288cf1',
    sub: '#FFB100', // orange
    red: '#ee3e3a',
    bgYellow: '#FFFAEE',
    text: '#394353',
  },
  shadow: '0px 3px 8px -2px #0006',
  tag: {
    borderRadius: 30,
    padding: '2px 8px',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
    fontSize: 18,
    backgroundColor: '#fff',
  },
  radius: 16,
};

module.exports = Theme;
