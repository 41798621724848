import styled from 'styled-components';
import React from 'react';
import {useOutlet, useOutletSetter} from 'reconnect.js';
import * as User from 'rev.sdk.js/Actions/User';
import {Layout, Menu, message, Result} from 'antd';
import {withLoginRequired} from 'rev.sdk.js/Components/LoginRequired';
import SiteNavBar from '../SiteNavBar';
import LoginRequired from '../LoginRequired';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
import constants from '../../constants';
import Config from '../../../data.json';
import AdminSearchUserModal from '../AdminSearchUserModal';
import AdminSearchProductModal from '../AdminSearchProductModal';
import AdminSearchArticleModal from '../AdminSearchArticleModal';
import AdminSearchStoreModal from '../AdminSearchStoreModal';
import AdminSearchCouponTemplateModal from '../AdminSearchCouponTemplateModal';
import * as PermissionUtil from '../../Utils/PermissionUtil';

const SiteInfo = {
  icon: '/images/logo.png',
  title: 'LAYA Admin',
  subtitle: 'Dashboard',
};

const Routes = [
  {name: '首頁', path: '/admin/'},
  {name: '設定', path: '/admin/site/'},
  {name: '商品', path: '/admin/products/'},
  {name: '訂單', path: '/admin/store_orders/'},
  {name: '文章', path: '/admin/articles/'},
  {name: '推播', path: '/admin/push_notifications/'},
  {name: '用戶', path: '/admin/users/'},
  // ...(Config.next
  //   ? [
  //       {name: '點數', path: '/admin/user_points/'},
  //       {name: '兌換紀錄', path: '/admin/point_exchange_histories/'},
  //     ]
  //   : []),
  {
    name: '分店',
    path: '/admin/stores/',
    // path: '__group-store',
    // subroutes: [
    // {name: '公開資訊', path: '/admin/stores/'},
    // {name: '私有資訊', path: '/admin/store_secrets/'},
    // {name: '促銷活動', path: '/admin/store_promotions/'},
    // {name: 'POS庫存更新', path: '/admin/pos_stock_records/'},
    // ...(Config.next
    //   ? [{name: 'POS設定', path: '/admin/store_pos_configs/'}]
    //   : []),
    // ],
  },
  {
    name: '活動',
    path: '/admin/events/',
  },
  {
    name: '舊版活動',
    path: '/admin/legacy_event/',
  },
  {
    name: '優惠券樣板',
    path: '/admin/coupon_templates',
  },
  {
    name: '分店群組',
    path: '/admin/store_groups',
  },
  {name: '重設密碼', path: 'reset-password'},
  {name: '登出', path: 'logout'},
];

function getSelectedMenuKey() {
  if (typeof window === 'undefined') {
    return '';
  }

  const pathname = window.location.pathname;
  if (pathname[pathname.length - 1] !== '/') {
    return pathname + '/';
  }
  return pathname;
}

function AdminLayout(props) {
  const {children, location} = props;
  const [dimension] = useOutlet('dimension');
  const showResetPasswordModal = useOutletSetter('reset-password-modal');
  const [initialized, setInitialized] = React.useState(false);
  const [showMobileMenu, setShowMobileMenu] = React.useState(false);
  const mobile = !dimension.rwd || dimension.rwd === 'mobile';
  const [user] = useOutlet('user');
  const [stores, setStores] = useOutlet('stores');
  const [coupon_templates, setCouponTemplates] = useOutlet('coupon_templates');

  const permissionAllowed = React.useMemo(
    () =>
      PermissionUtil.readable({
        path: location.pathname,
        user,
      }),
    [location.pathname, user],
  );

  const getMenuItemProps = (path) => {
    if (path === 'reset-password') {
      return {
        onClick: () => showResetPasswordModal({admin: true}),
      };
    } else if (path === 'logout') {
      return {
        onClick: async () => {
          await User.logout(true);
          AppActions.navigate('/admin');
        },
      };
    }
    return {
      selected: path === location.pathname,
      onClick: () => AppActions.navigate(path),
    };
  };

  React.useEffect(() => {
    setShowMobileMenu(false);
  }, [location]);

  React.useEffect(() => {
    const _initializeSiteConfig = async () => {
      AppActions.setLoading(true);
      try {
        let results = await JStorage.fetchDocuments('site', {}, null, null);
        for (let key in constants.SITE_CONFIG) {
          let name = constants.SITE_CONFIG[key].value;
          if (!results.find((r) => r.name === name)) {
            await JStorage.createDocument('site', {name});
          }
        }
      } catch (ex) {
        message.warn('設定初始化失敗');
      }
      AppActions.setLoading(false);
      setInitialized(true);
    };

    if (!initialized && user) {
      _initializeSiteConfig();
    }
  }, [initialized, user]);

  React.useEffect(() => {
    async function fetchData() {
      setStores(await AppActions.fetchStores({allowAll: true}));
      setCouponTemplates(await JStorage.fetchAllDocuments('coupon_template'));
    }

    fetchData();
  }, []);

  const siderStyle = {
    overflow: 'auto',
    height: '100vh',
    position: 'fixed',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    left: 0,
  };

  if (mobile) {
    siderStyle.left = showMobileMenu ? 0 : -200;
  }

  return (
    <Layout>
      <Layout style={{marginLeft: mobile ? 0 : 200, backgroundColor: 'white'}}>
        <Wrapper id="rev-admin-layout">
          {!permissionAllowed ? <PermissionDenied /> : initialized && children}
        </Wrapper>
      </Layout>

      <Layout.Sider theme="light" style={siderStyle}>
        <AppHeader
          style={{marginBottom: 40, cursor: 'pointer'}}
          onClick={() => AppActions.navigate('/')}
        />

        <Menu mode="inline" selectedKeys={[getSelectedMenuKey()]}>
          {Routes.map(({name, path, subroutes = []}, idx) => {
            if (subroutes.length === 0) {
              return (
                <Menu.Item key={path} {...getMenuItemProps(path)}>
                  {name}
                </Menu.Item>
              );
            }
            return (
              <Menu.SubMenu key={path} title={name}>
                {subroutes.map(({name, path}) => (
                  <Menu.Item key={path} {...getMenuItemProps(path)}>
                    {name}
                  </Menu.Item>
                ))}
              </Menu.SubMenu>
            );
          })}
        </Menu>
      </Layout.Sider>

      <AdminSearchUserModal />
      <AdminSearchProductModal />
      <AdminSearchArticleModal />
      <AdminSearchStoreModal />
      <AdminSearchCouponTemplateModal />

      {mobile && (
        <MobileMainMenu
          onClick={() => {
            setShowMobileMenu(!showMobileMenu);
          }}>
          選單
        </MobileMainMenu>
      )}
    </Layout>
  );
}

function AppHeader(props) {
  const {style = {}, onClick} = props;

  return (
    <AppHeaderWrapper style={style} onClick={onClick}>
      <figure>
        <img src={SiteInfo.icon} alt="site icon" />
      </figure>

      <div className="content">
        <p>{SiteInfo.title}</p>
        <p style={{color: '#ccc'}}>{SiteInfo.subtitle}</p>
      </div>
    </AppHeaderWrapper>
  );
}

function PermissionDenied() {
  return (
    <Result
      status="403"
      title="權限不足"
      subTitle="無權限訪問此頁面"
      style={{
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  );
}

const Wrapper = styled.div`
  &#rev-admin-layout button.array-item-add {
    & span:first-child {
      transform: scale(1.2) translateY(1px);
    }
    & span:last-child {
      display: none;
    }
  }
`;

const AppHeaderWrapper = styled.header`
  background-color: white;
  display: flex;
  align-items: center;

  & > figure {
    padding: 10px;
    margin: 0px;

    & > img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }

  & > .content {
    padding: 8px;

    & p {
      padding: 0;
      margin: 0;
    }
  }
`;

const MobileMainMenu = styled.button`
  position: fixed;
  z-index: 2;
  right: 32px;
  bottom: 32px;
  z-index: 1;
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: none;
  outline: none;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default withLoginRequired(AdminLayout, {
  admin: true,
  SiteNavBar,
  renderLoginRequired: LoginRequired,
});
