import React from 'react';
import styled from 'styled-components';
import {Modal, Button} from 'antd';
import * as TimeUtil from '../../Utils/TimeUtil';
import Constants from '../../constants';
import Theme from '../../Theme';

const DtOptions = [
  {value: 'today', display: '今天'},
  {value: 'tomorrow', display: '明天'},
];

export default function SelectOrderTimeModal(props) {
  const {visible, setVisible, onResult, store: selectedStore} = props;
  const [selectedDt, setSelectedDt] = React.useState('today');
  const [selectedTime, setSelectedTime] = React.useState(null);

  React.useEffect(() => {
    setSelectedTime(null);
  }, [selectedDt]);

  const timeOptions =
    selectedDt && selectedStore
      ? TimeUtil.getTimeOptions({
          store: selectedStore,
          dt: selectedDt,
        })
      : [];

  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        padding: 0,
        borderRadius: 8,
        height: '80%',
        maxHeight: 'calc(100vh - 200px)',
        overflowY: 'scroll',
      }}
      style={{padding: 0, borderRadius: 8}}
      footer={
        timeOptions.length !== 0 ? (
          <div style={{display: 'flex'}}>
            <Button
              size="large"
              type="primary"
              style={{flex: 1}}
              disabled={!(selectedDt && selectedTime)}
              onClick={() => {
                onResult({dt: selectedDt, time: selectedTime});
                setVisible(false);
              }}>
              確認
            </Button>
          </div>
        ) : null
      }
      destroyOnClose={true}>
      <Wrapper>
        <div style={{display: 'flex', justifyContent: 'center', padding: 5}}>
          {DtOptions.map((dt) => {
            const selected = selectedDt === dt.value;
            return (
              <div
                key={dt.value}
                onClick={() => setSelectedDt(dt.value)}
                style={{
                  padding: '8px 15px',
                  borderWidth: 1,
                  borderColor: selected ? Theme.colors.main : '#ccc',
                  backgroundColor: selected ? Theme.colors.main : 'transparent',
                  margin: 5,
                  borderRadius: 30,
                  color: selected ? 'white' : '#ccc',
                  fontSize: 20,
                  cursor: 'pointer',
                }}>
                {dt.display}
              </div>
            );
          })}
        </div>

        {!selectedStore ? (
          <div style={{fontSize: 18, margin: 16, textAlign: 'center'}}>
            請先選擇門市
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            paddingBottom: timeOptions.length === 0 ? 0 : 30,
          }}>
          {timeOptions.length === 0 && (
            <div style={{fontSize: 18, margin: 10}}>無可用時段</div>
          )}
          {timeOptions.map((opt) => {
            const selected = opt === selectedTime;
            return (
              <Button
                key={opt}
                type={selected ? 'primary' : 'default'}
                style={{margin: 8}}
                onClick={() => setSelectedTime(opt)}>
                {opt}
              </Button>
            );
          })}
        </div>
      </Wrapper>
    </Modal>
  );
}

const Wrapper = styled.div`
  padding: 30px;
`;
