import React from 'react';
import styled from 'styled-components';

export default function PleaseWait(props) {
  const {title, msg} = props;
  return (
    <Wrapper>
      <img src="/images/logo.png" alt="revicon" />
      <h2>{title || '自動登入中'}</h2>
      <p>{msg || '請稍後...'}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > img {
    width: 250px;
    height: 250px;
    object-fit: center;
    margin: 20px;
  }
  & > h2 {
    font-size: 2.5rem;
  }
  & > p {
    font-size: 2rem;
    color: #ccc;
  }
`;
