import React from 'react';
import useDetectDimension from 'rev.sdk.js/Hooks/useDetectDimension';
import LoginModal from '../Components/LoginModal';
import ResetPasswordModal from '../Components/ResetPasswordModal';
import GlobalSpinner from '../Components/GlobalSpinner';
import {onCtxRendered} from '../Utils/CtxUtil';

function Provider(props) {
  useDetectDimension();

  React.useEffect(() => {
    console.log('AppCtx effect hook');
    onCtxRendered();
  }, []);

  console.log('AppCtx rendered');

  return (
    <>
      {props.children}

      <LoginModal />
      <ResetPasswordModal />
      <GlobalSpinner />
    </>
  );
}

export {Provider};
