import React from 'react';
import styled from 'styled-components';
import {ChevronRight} from '@styled-icons/boxicons-regular/ChevronRight';
import {Clock} from '@styled-icons/evil/Clock';
import {Map} from '@styled-icons/bootstrap/Map';
import {Telephone} from '@styled-icons/bootstrap/Telephone';
import Theme from '../../Theme';

function getOpenHourDisplay(store) {
  const openHours = store.opening_hours;
  const day = new Date().getDay() || 7;

  if (!openHours || !openHours[day]) {
    return '';
  }

  const dayLabels = {
    1: '星期一',
    2: '星期二',
    3: '星期三',
    4: '星期四',
    5: '星期五',
    6: '星期六',
    7: '星期日',
  };

  return `${dayLabels[day]} ${
    openHours[day] === '00:00-00:00' ? '固定公休日' : openHours[day]
  }`;
}

export default function StoreItem(props) {
  const {store, selected, onClick, withChevronRight = true} = props;
  return (
    <Wrapper onClick={onClick} selected={selected}>
      <div className="title">
        <h2>{store.name}</h2>
        {withChevronRight && <ChevronRight size={24} color="black" />}
      </div>

      <div className="content">
        <div>
          <img src="/images/icon-time.png" className="icon" />
          <div>{getOpenHourDisplay(store)}</div>
        </div>

        <div>
          <img src="/images/icon-store.png" className="icon" />
          <div>{store.address || '- - -'}</div>
        </div>

        <div>
          <img src="/images/icon-tel.png" className="icon" />
          <div>{store.phone || '- - -'}</div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border-radius: 4px;
  background-color: ${(props) =>
    props.selected ? '#D6EAF6' : Theme.colors.bgYellow};
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  transition: all 200ms;

  & > .title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & > *:first-child {
      margin-right: 8px;
    }

    & > h2 {
      flex: 1;
      font-size: 1.5rem;
    }
  }

  & > .content {
    & > * {
      & > *:first-child {
        margin-right: 8px;
      }

      display: flex;
      align-items: center;
    }

    & > *:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  }

  & .icon {
    weight: 20px;
    height: 20px;
  }
`;
