const TZ_DIFF = '08:00';

function _leadingZero(x) {
  return ('00' + x.toString()).slice(-2);
}

function _localDateStr(dt) {
  return `${dt.getFullYear()}-${_leadingZero(dt.getMonth() + 1)}-${_leadingZero(
    dt.getDate(),
  )}`;
}

function isStoreOpen({store, dt}) {
  // FIXME
  let dayIdx = dt.getDay();
  dayIdx = dayIdx === 0 ? 7 : dayIdx;

  const bizHour = store.opening_hours && store.opening_hours[dayIdx];

  if (!bizHour) {
    return false;
  }

  const [startTime, endTime] = bizHour.split('-');
  const startDt = new Date(`${_localDateStr(dt)}T${startTime}+${TZ_DIFF}`);
  const endDt = new Date(`${_localDateStr(dt)}T${endTime}+${TZ_DIFF}`);

  if (isNaN(startDt.getTime()) || isNaN(endDt.getTime())) {
    return false;
  }

  return dt.getTime() >= startDt.getTime() && endDt.getTime() > dt.getTime();
}

module.exports = {
  isStoreOpen,
};
