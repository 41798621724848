function diff(_a, _b) {
  const a = {..._a};
  const b = {..._b};
  delete a.created;
  delete a.updated;
  delete b.created;
  delete b.updated;

  if (JSON.stringify(a) === JSON.stringify(b)) {
    return {};
  }

  return Object.keys(b).reduce((sum, key) => {
    const valA = a[key];
    const valB = b[key];

    if (
      typeof valA === 'undefined' ||
      typeof valB === 'undefined' ||
      valA === null ||
      valB === null
    ) {
      if (valA !== valB) {
        sum[key] = valB;
        return sum;
      } else {
        return sum;
      }
    }

    if (
      typeof valB === 'boolean' ||
      typeof valB === 'string' ||
      typeof valB === 'number' ||
      typeof valB === 'bigint'
    ) {
      if (valA !== valB) {
        sum[key] = valB;
        return sum;
      } else {
        return sum;
      }
    }
    if (
      typeof valB === 'object' &&
      JSON.stringify(valB) !== JSON.stringify(valA)
    ) {
      sum[key] = valB;
      return sum;
    }
    return sum;
  }, {});
}

function isEmptyObject(obj) {
  for (let p in obj) {
    return false;
  }
  return true;
}

diff.isEmptyObject = isEmptyObject;
module.exports = diff;
