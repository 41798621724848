import {getNewOutlet} from 'reconnect.js';
import {buildCatDisplayMap} from 'rev.sdk.js/Utils/buildCatDisplayMap';
import './featureOptions';

/**
 * **************************************************
 * common / basic
 * **************************************************
 */
getNewOutlet('user', null, {autoDelete: false});
getNewOutlet('landing', null, {autoDelete: false});
getNewOutlet('config', null, {autoDelete: false});
getNewOutlet('dimension', {rwd: 'mobile'}, {autoDelete: false});
getNewOutlet('actions', null, {autoDelete: false});
getNewOutlet('ApiUtil', {}, {autoDelete: false});
getNewOutlet(
  'cart',
  {
    items: [],
    extra_items: [],
    discount_items: [],
  },
  {autoDelete: false},
);
getNewOutlet(
  'routes',
  {
    getRoute: (route, params) => {
      return null;
    },
  },
  {autoDelete: false},
);

/**
 * **************************************************
 * modal / spinner related
 * **************************************************
 */
getNewOutlet('loading', false, {autoDelete: false});
getNewOutlet('login-modal', false, {autoDelete: false});
getNewOutlet('reset-password-modal', false, {autoDelete: false});
getNewOutlet('promo-app-modal', false, {autoDelete: false});

/**
 * **************************************************
 * product / article related
 * **************************************************
 */
const defaultCats = [
  {
    name: 'all',
    display: '所有分類',
    items: [],
  },
];

getNewOutlet('categories', defaultCats, {autoDelete: false});
getNewOutlet('categoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});
getNewOutlet(
  'sortOptions',
  [
    {name: '-created', display: '上架時間(由新到舊)'},
    {name: 'created', display: '上架時間(由舊到新)'},
    {name: 'price', display: '價格(由低到高)'},
    {name: '-price', display: '價格(由高到低)'},
  ],
  {autoDelete: false},
);

getNewOutlet('articleCategories', defaultCats, {autoDelete: false});
getNewOutlet('articleCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});
getNewOutlet(
  'articleSortOptions',
  [
    {name: '-created', display: '發布時間(由新到舊)'},
    {name: 'created', display: '發布時間(由舊到新)'},
  ],
  {autoDelete: false},
);

getNewOutlet('promoCategories', defaultCats, {autoDelete: false});
getNewOutlet('promoCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet('mealCategories', defaultCats, {autoDelete: false});
getNewOutlet('mealCategoryDisplayMap', buildCatDisplayMap(defaultCats), {
  autoDelete: false,
});

getNewOutlet('variantGroups', [], {autoDelete: false});

getNewOutlet('LayaCart', null, {autoDelete: false});
getNewOutlet('LayaCartPrice', null, {autoDelete: false});
getNewOutlet('LayaCartExtra', {loading: false}, {autoDelete: false});
getNewOutlet('hotsearch', [], {autoDelete: false});
getNewOutlet('product_attribute', [], {autoDelete: false});

getNewOutlet(
  'SelectStore',
  {
    visible: false,
    store: null,
  },
  {autoDelete: false},
);

getNewOutlet(
  'SelectOrderType',
  {
    visible: false,
    data: {},
  },
  {autoDelete: false},
);

getNewOutlet('LocalOrders', [], {autoDelete: false});

// used in admin article search modal
getNewOutlet('coupon_templates', [], {autoDelete: false});
getNewOutlet('stores', [], {autoDelete: false});
getNewOutlet('products', [], {autoDelete: false});
getNewOutlet('articles', [], {autoDelete: false});
