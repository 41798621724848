import * as AsyncStorage from 'rev.sdk.js/Utils/AsyncStorage';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

async function getLocalData(key, defaultData) {
  let data = defaultData;

  try {
    const localData = await AsyncStorage.getItem(key);
    if (localData) {
      data = JSON.parse(localData);
    }
  } catch (ex) {}

  return data;
}

async function setLocalData(key, nextData) {
  await AsyncStorage.setItem(key, JSON.stringify(nextData));
}

export async function get() {
  let cart = {config: {}, items: []};

  try {
    const localCart = await AsyncStorage.getItem('local-cart');
    if (localCart) {
      cart = JSON.parse(localCart);
    }
  } catch (ex) {}

  return cart;
}

export async function set(nextCart) {
  await AsyncStorage.setItem('local-cart', JSON.stringify(nextCart));
}

export async function getStore() {
  let store = null;

  try {
    const localStore = await AsyncStorage.getItem('local-store');
    if (localStore) {
      store = JSON.parse(localStore);
      try {
        store = await JStorage.fetchOneDocument('store', {id: store.id});
      } catch (ex) {
        console.warn('fail to refresh local saved store', ex);
      }
    }
  } catch (ex) {}

  return store;
}

export async function setStore(nextStore) {
  await AsyncStorage.setItem('local-store', JSON.stringify(nextStore));
}

export async function getOrderType() {
  let orderType = {};

  try {
    const localOrderType = await AsyncStorage.getItem('local-order-type');
    if (localOrderType) {
      orderType = JSON.parse(localOrderType);
    }
  } catch (ex) {}

  return orderType;
}

export async function setOrderType(nextOrderType) {
  await AsyncStorage.setItem('local-order-type', JSON.stringify(nextOrderType));
}

export async function getLocalOrders() {
  return getLocalData('local-orders', []);
}

export async function setLocalOrders(nextData) {
  return setLocalData('local-orders', nextData);
}
