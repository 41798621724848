const constants = require('../constants');
const STEP_MS = 5 * 60 * 1000;
const TZ_DIFF = '+08:00';
const OPEN_BUFFER_MINUTES = 15; // minutes

function _parseOpenHours({store, day}) {
  try {
    return store.opening_hours[day || 7].split('-');
  } catch (ex) {
    return ['', ''];
  }
}

function getTimeOptions({store, dt: type}) {
  function isValidTimeStr(timeStr) {
    if (typeof timeStr === 'string') {
      const [hh, mm] = timeStr.split(':');
      const h = parseInt(hh, 10);
      const m = parseInt(mm, 10);
      if (!isNaN(h) && h >= 0 && h < 24 && !isNaN(m) && m >= 0 && m < 60) {
        return true;
      }
    }
    return false;
  }

  function prependTwoZero(value) {
    return ('00' + value.toString()).slice(-2);
  }

  const result = [];
  const now = new Date();
  const [STORE_OPEN_TIME, STORE_CLOSE_TIME] = _parseOpenHours({
    store,
    day: type === 'today' ? now.getDay() : (now.getDay() + 1) % 7,
  });

  if (STORE_OPEN_TIME === '00:00' && STORE_CLOSE_TIME === '00:00') {
    return [];
  } else if (!isValidTimeStr(STORE_OPEN_TIME)) {
    console.warn('[WARNING] invalid store open time');
    return [];
  } else if (!isValidTimeStr(STORE_CLOSE_TIME)) {
    console.warn('[WARNING] invalid store close time');
    return [];
  }

  if (type === 'today') {
    const closeDate = new Date(
      `${now.getFullYear()}-${prependTwoZero(
        now.getMonth() + 1,
      )}-${prependTwoZero(now.getDate())}T${STORE_CLOSE_TIME}${TZ_DIFF}`,
    );

    closeDate.setMinutes(closeDate.getMinutes() - OPEN_BUFFER_MINUTES);

    const {preparation_time = constants.default.DEFAULT_STORE_PREPARATION} =
      store.preparation_config || {};
    const currentTempDateMs = now.getTime() + preparation_time * 60 * 1000; // now + store prepare time

    let startDate = new Date(
      `${now.getFullYear()}-${prependTwoZero(
        now.getMonth() + 1,
      )}-${prependTwoZero(now.getDate())}T${STORE_OPEN_TIME}${TZ_DIFF}`,
    ); // store start time

    startDate.setMinutes(startDate.getMinutes() + preparation_time);

    const currentDate = new Date(
      currentTempDateMs - (currentTempDateMs % STEP_MS) + STEP_MS,
    ); // align to 5min

    startDate =
      startDate.getTime() > currentDate.getTime() ? startDate : currentDate;

    while (startDate.getTime() <= closeDate.getTime()) {
      result.push(
        `${prependTwoZero(startDate.getHours())}:${prependTwoZero(
          startDate.getMinutes(),
        )}`,
      );
      startDate = new Date(startDate.getTime() + STEP_MS);
    }
  } else {
    const tomorrow = new Date(now.getTime() + 24 * 3600 * 1000);
    const closeDate = new Date(
      `${tomorrow.getFullYear()}-${prependTwoZero(
        tomorrow.getMonth() + 1,
      )}-${prependTwoZero(tomorrow.getDate())}T${STORE_CLOSE_TIME}${TZ_DIFF}`,
    );

    closeDate.setMinutes(closeDate.getMinutes() - OPEN_BUFFER_MINUTES);

    const {preparation_time = constants.default.DEFAULT_STORE_PREPARATION} =
      store.preparation_config || {};

    let startDate = new Date(
      `${tomorrow.getFullYear()}-${prependTwoZero(
        tomorrow.getMonth() + 1,
      )}-${prependTwoZero(tomorrow.getDate())}T${STORE_OPEN_TIME}${TZ_DIFF}`,
    );

    startDate.setMinutes(startDate.getMinutes() + preparation_time);

    while (startDate.getTime() <= closeDate.getTime()) {
      result.push(
        `${prependTwoZero(startDate.getHours())}:${prependTwoZero(
          startDate.getMinutes(),
        )}`,
      );
      startDate = new Date(startDate.getTime() + STEP_MS);
    }
  }

  return result;
}

module.exports = {
  getTimeOptions,
};
