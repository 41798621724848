import * as CustomConstants from './custom';

const THEME_COLOR = 'rgb(159, 177, 24)';

const SITE_CONFIG = {
  config: {display: '設定', value: 'config'},
  landing: {display: '首頁設定', value: 'landing'},
  product_attribute: {display: '商品屬性', value: 'product_attribute'},
  product_category: {display: '選單分類', value: 'product_category'},
  variant_group: {display: '商品選項群組', value: 'variant_group'},
  article_category: {display: '文章分類', value: 'article_category'},
  navbar_config: {display: '頁眉設定', value: 'navbar_config'},
  promotion_category: {display: '推薦分類', value: 'promotion_category'},
  hotsearch: {display: '熱門關鍵字', value: 'hotsearch'},
  meal: {display: '菜單本', value: 'meal'},
  scratch_card_event: {display: '刮刮卡活動', value: 'scratch_card_event'},
  draw_lots_event: {display: '抽獎活動', value: 'draw_lots_event'},
  coupon_distribution_event: {display: '', value: 'coupon_distribution_event'},
  pos_product_list: {display: 'POS商品頁面設定', value: 'pos_product_list'},
};

const constants = {
  THEME_COLOR,
  SITE_CONFIG,
  DEFAULT_STORE_PREPARATION: 15,
  ...CustomConstants,
};

export default constants;
