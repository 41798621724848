import {useOutlet, getOutlet} from 'reconnect.js';
import * as LocalCartUtil from '../../Utils/LocalCartUtil';

const SelectOrderTypeOutlet = getOutlet('SelectOrderType');

export function useSelectedOrderType() {
  const [innerState] = useOutlet('SelectOrderType');
  return innerState.data;
}

export function unsetSelectedData() {
  const nextData = {
    type: 'take-away',
    time: null,
  };

  SelectOrderTypeOutlet.update({
    ...SelectOrderTypeOutlet.getValue(),
    data: nextData,
    visible: false,
  });

  LocalCartUtil.setOrderType(nextData);
}

export function unsetSelectedTime() {
  const nextData = {
    ...SelectOrderTypeOutlet.getValue().data,
    time: null,
  };
  SelectOrderTypeOutlet.update({
    ...SelectOrderTypeOutlet.getValue(),
    data: nextData,
  });
  LocalCartUtil.setOrderType(nextData);
}

export function setSelectedTable(table) {
  const nextData = {
    type: 'eat-here',
    table,
    time: null,
  };

  SelectOrderTypeOutlet.update({
    ...SelectOrderTypeOutlet.getValue(),
    data: nextData,
    visible: false,
  });

  LocalCartUtil.setOrderType(nextData);
}

export function showSelectOrderTypeModal(visible) {
  SelectOrderTypeOutlet.update({
    ...SelectOrderTypeOutlet.getValue(),
    visible,
  });
}

export function onOrderTimeSelected(setInnerField, {dt, time}) {
  function leadingZero(value) {
    return ('00' + value.toString()).slice(-2);
  }

  const now = new Date();
  const selectedDt = new Date(
    `${now.getFullYear()}-${leadingZero(now.getMonth() + 1)}-${leadingZero(
      now.getDate(),
    )}T00:00+08:00`,
  );

  // because we cannot directly add this date into string format, which might create an invalid date
  if (dt === 'tomorrow') {
    selectedDt.setDate(selectedDt.getDate() + 1);
  }

  const selectedTimeStr = `${selectedDt.getFullYear()}-${leadingZero(
    selectedDt.getMonth() + 1,
  )}-${leadingZero(selectedDt.getDate())} ${time}:00`;

  setInnerField('time', selectedTimeStr);
}
