import {getOutlet} from 'reconnect.js';
import * as AppActions from '../AppActions';
import * as LocalCartUtil from './LocalCartUtil';

const LayaCart = getOutlet('LayaCart');
const LayaCartPrice = getOutlet('LayaCartPrice');
const SelectStoreOutlet = getOutlet('SelectStore');
const SelectOrderTypeOutlet = getOutlet('SelectOrderType');
const LocalOrdersOutlet = getOutlet('LocalOrders');

export async function loadLocalOrders() {
  LocalOrdersOutlet.update(await LocalCartUtil.getLocalOrders());
}

export async function loadLocalStore() {
  const nextStore = await LocalCartUtil.getStore();
  SelectStoreOutlet.update({
    ...SelectStoreOutlet.getValue(),
    store: nextStore,
  });
}

export async function loadLocalOrderType() {
  const nextOrderType = await LocalCartUtil.getOrderType();
  SelectOrderTypeOutlet.update({
    ...SelectOrderTypeOutlet.getValue(),
    data: nextOrderType,
  });
}

export async function loadLocalCart() {
  const nextData = await LocalCartUtil.get();
  try {
    const {total, subtotal, ...nextCart} = await AppActions.calcCartPrice(
      nextData,
      SelectStoreOutlet.getValue().store,
      SelectOrderTypeOutlet.getValue().data?.time,
    );
    LayaCart.update(nextCart);
    LayaCartPrice.update({total, subtotal});
  } catch (ex) {
    console.warn('LayaCartUtil.loadLocalCart ex', ex);
    LayaCart.update({config: {}, items: []});
    LayaCartPrice.update({total: 0, subtotal: 0});
    SelectStoreOutlet.update({
      store: null,
    });
    SelectOrderTypeOutlet.update({
      data: {},
    });
  }
}

export async function refreshCart() {
  if (!LayaCart.getValue()) {
    console.log('no cart');
    return;
  }

  const {total, subtotal, ...nextCart} = await AppActions.calcCartPrice(
    LayaCart.getValue(),
    SelectStoreOutlet.getValue().store,
    SelectOrderTypeOutlet.getValue().data?.time,
  );
  await LocalCartUtil.set(nextCart);
  LayaCart.update(nextCart);
  LayaCartPrice.update({total, subtotal});
}

export async function addItem({
  product,
  qty,
  variants,
  drink,
  add_on,
  note = '',
}) {
  if (!LayaCart.getValue()) {
    return;
  }

  const prevData = {...LayaCart.getValue()};
  const nextData = {
    ...prevData,
    items: [...prevData.items, {product, qty, variants, drink, add_on, note}],
  };

  const {total, subtotal, ...nextCart} = await AppActions.calcCartPrice(
    nextData,
    SelectStoreOutlet.getValue().store,
    SelectOrderTypeOutlet.getValue().data?.time,
  );
  await LocalCartUtil.set(nextCart);
  LayaCart.update(nextCart);
  LayaCartPrice.update({total, subtotal});
}

export async function editItem(
  idx,
  {product, qty, variants, drink, add_on, note = ''},
) {
  if (!LayaCart.getValue()) {
    return;
  }

  const prevData = {...LayaCart.getValue()};
  let nextItems = [...prevData.items];

  if (qty === 0) {
    nextItems.splice(idx, 1);
  } else {
    nextItems[idx] = {product, qty, variants, drink, add_on, note};
  }
  const nextData = {
    ...prevData,
    items: nextItems,
  };

  const {total, subtotal, ...nextCart} = await AppActions.calcCartPrice(
    nextData,
    SelectStoreOutlet.getValue().store,
    SelectOrderTypeOutlet.getValue().data?.time,
  );
  await LocalCartUtil.set(nextCart);
  LayaCart.update(nextCart);
  LayaCartPrice.update({total, subtotal});
}

export async function removeItem(idx) {
  if (!LayaCart.getValue()) {
    return;
  }

  const prevData = {...LayaCart.getValue()};
  let nextItems = [...prevData.items];
  nextItems.splice(idx, 1);
  const nextData = {
    ...prevData,
    items: nextItems,
  };

  const {total, subtotal, ...nextCart} = await AppActions.calcCartPrice(
    nextData,
    SelectStoreOutlet.getValue().store,
    SelectOrderTypeOutlet.getValue().data?.time,
  );
  await LocalCartUtil.set(nextCart);
  LayaCart.update(nextCart);
  LayaCartPrice.update({total, subtotal});
}

export async function clearCart() {
  if (!LayaCart.getValue()) {
    return;
  }

  const nextData = {
    config: {},
    items: [],
  };

  const {total, subtotal, ...nextCart} = await AppActions.calcCartPrice(
    nextData,
    SelectStoreOutlet.getValue().store,
    SelectOrderTypeOutlet.getValue().data?.time,
  );
  await LocalCartUtil.set(nextCart);
  LayaCart.update(nextCart);
  LayaCartPrice.update({total, subtotal});
}

export function validateCart() {
  const cart = LayaCart.getValue();
  const storeInfo = getOutlet('SelectStore').getValue()?.store || {};
  const {subtotal, total} = LayaCartPrice.getValue() || {};

  // console.log('cart', cart);

  if (!cart) {
    return [false, '找不到購物車'];
  }

  if (
    cart.store &&
    cart.store.status !== 'VALID' &&
    cart.store.error_msg === '請重新選擇時間'
  ) {
    return [
      false,
      '原取餐時段繁忙，請延後取餐時間',
      {
        action: () => {
          SelectOrderTypeOutlet.update({
            ...SelectOrderTypeOutlet.getValue(),
            visible: true,
          });
        },
        text: '點我重新編輯取餐資訊',
      },
    ];
  }

  if (cart.store && cart.store.status !== 'VALID' && cart.store.error_msg) {
    return [false, cart.store.error_msg];
  }

  if (cart.store && cart.store.status !== 'VALID') {
    return [false, '請移除所有無法供應的品項'];
  }

  const cartPriceLimit =
    typeof storeInfo.order_amount_limit === 'number' &&
    storeInfo.order_amount_limit > 0
      ? storeInfo.order_amount_limit
      : 100000;

  if (total > cartPriceLimit) {
    return [false, '超過單筆消費金額上限，請分次下單'];
  }

  if (
    cart.config.time &&
    typeof storeInfo.preparation_config?.preparation_time === 'number' &&
    Date.now() + storeInfo.preparation_config.preparation_time * 60 * 1000 >
      new Date(cart.config.time).getTime()
  ) {
    return [
      false,
      '預約時間請勿小於' +
        storeInfo.preparation_config.preparation_time +
        '分鐘',
    ];
  }

  if (
    cart.config.time &&
    typeof storeInfo.preparation_config?.doubling_threshold === 'number' &&
    subtotal >= storeInfo.preparation_config.doubling_threshold &&
    Date.now() + 2 * storeInfo.preparation_config.preparation_time * 60 * 1000 >
      new Date(cart.config.time).getTime()
  ) {
    return [
      false,
      `訂單超過${
        storeInfo.preparation_config.doubling_threshold
      }元，預約時間請勿小於${
        2 * storeInfo.preparation_config.preparation_time
      }分鐘`,
    ];
  }

  return [true];
}

export function validateCartItems() {
  const cart = LayaCart.getValue();

  if (!cart) {
    return [false, '找不到購物車'];
  }

  if (cart.items.length === 0) {
    return [false, '購物車品項是空的'];
  }

  if (cart.items.length > 20) {
    return [false, '超過購物車數量上限，請分次下單'];
  }

  for (const item of cart.items) {
    if (item.status && item.status?.status_code !== 'NORMAL_SUPPLY') {
      return [false, item.status?.detail];
    }
  }

  return [true];
}

export function validateCartItem(item) {
  const cart = LayaCart.getValue();

  if (item.status && item.status?.status_code !== 'NORMAL_SUPPLY') {
    return [false, item.status?.detail];
  }

  return [true];
}
