// https://github.com/defunctzombie/node-process#browser-implementation
const process = require('process/browser');
// https://github.com/feross/buffer#usage
const Buffer = require('buffer/').Buffer;

if (typeof window !== 'undefined') {
  window.process = process;
  window.Buffer = Buffer;
} else {
  global.window = {
    navigator: {},
  };
}

// window.requestAnimationFrame / cancelAnimationFrame
(function () {
  var lastTime = 0;

  if (!window.requestAnimationFrame)
    window.requestAnimationFrame = function (callback, element) {
      var currTime = new Date().getTime();
      var timeToCall = Math.max(0, 16 - (currTime - lastTime));
      var id = window.setTimeout(function () {
        callback(currTime + timeToCall);
      }, timeToCall);
      lastTime = currTime + timeToCall;
      return id;
    };

  if (!window.cancelAnimationFrame)
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id);
    };
})();

// window.performance.now
(function () {
  if (window.performance && window.performance.now) {
    return;
  }

  window.performance = window.performance || {};

  if (
    window.performance.timing &&
    window.performance.timing.navigationStart &&
    window.performance.mark &&
    window.performance.clearMarks &&
    window.performance.getEntriesByName
  ) {
    window.performance.now = function () {
      window.performance.clearMarks('__PERFORMANCE_NOW__');
      window.performance.mark('__PERFORMANCE_NOW__');
      return window.performance.getEntriesByName('__PERFORMANCE_NOW__')[0]
        .startTime;
    };
  } else if ('now' in window.performance === false) {
    var nowOffset = Date.now();

    if (
      window.performance.timing &&
      window.performance.timing.navigationStart
    ) {
      nowOffset = window.performance.timing.navigationStart;
    }

    window.performance.now = function now() {
      return Date.now() - nowOffset;
    };
  }
})();
