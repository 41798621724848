import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Link from '../NavLink';
import constants from '../../constants';
import {Button} from 'antd';
import * as AppActions from '../../AppActions';
import {ShoppingBag} from '@styled-icons/boxicons-regular/ShoppingBag';
import OrderTypeHeader from '../OrderTypeHeader';
import {getTotalItemQtyInCart} from '../../Utils/CartUtil';
import Theme from '../../Theme';

export default function SiteNavBar(props) {
  const [cart] = useOutlet('LayaCart');
  const totalItemQty = getTotalItemQtyInCart(cart);

  return (
    <>
      <Wrapper style={{height: 64}}>
        <div className="content">
          <Logo style={{cursor: 'pointer'}}>
            <Link to="/" loading={800}>
              <img
                className="logo"
                src="/images/logo.png"
                alt="Logo"
                style={{
                  height: 48,
                  objectFit: 'contain',
                }}
              />
            </Link>
          </Logo>

          <div style={{marginLeft: 8}}>
            <OrderTypeHeader />
          </div>

          <div style={{display: 'flex', flex: 1, marginLeft: 20}} />

          <Button
            type="link"
            onClick={async () => {
              await AppActions.navigate('/checkout');
            }}>
            <Badge>
              <ShoppingBag size={24} color={Theme.colors.main} />
              {totalItemQty > 0 && <div className="count">{totalItemQty}</div>}
            </Badge>
          </Button>
        </div>
      </Wrapper>
    </>
  );
}

const Badge = styled.div`
  position: relative;

  & > .count {
    display: block;
    position: absolute;
    top: -12px;
    right: -12px;
    min-width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${Theme.colors.main};
    color: white;
    font-size: 0.7rem;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: 200ms;

  & > .content {
    height: var(--topNavBarHeight);
    display: flex;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    padding: 16px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;
