import {getOutlet, useOutlet} from 'reconnect.js';
import * as LocalCartUtil from '../../Utils/LocalCartUtil';

const SelectStoreOutlet = getOutlet('SelectStore');

export function useSelectedStore() {
  const [innerState] = useOutlet('SelectStore');
  return innerState.store;
}

export function setSelectedStore(store) {
  SelectStoreOutlet.update({
    ...SelectStoreOutlet.getValue(),
    store,
    visible: false,
  });
  LocalCartUtil.setStore(store);
}

export function showSelectStoreModal(visible) {
  SelectStoreOutlet.update({
    ...SelectStoreOutlet.getValue(),
    visible,
  });
}
