export function getItemSummary(cartItem, option = {hidePrice: false}) {
  const {product, variants = {}} = cartItem;
  const {hidePrice} = option;
  const variantDisplays = [];

  function findVariant(name) {
    return product.variants?.find((v) => v.name === name);
  }

  function signDisplay(value) {
    if (value >= 0) {
      return '+' + value;
    } else {
      return '-' + Math.abs(value);
    }
  }

  if (variants.set) {
    const v = findVariant('set');
    if (v) {
      const choice = v.choices.find((c) => c.name === variants.set);
      variantDisplays.push({
        ...choice,
        set: true,
      });
    }
  }

  for (const name in variants) {
    if (name !== 'set') {
      const v = findVariant(name);
      if (v) {
        if (v.type === 'bool') {
          variantDisplays.push({
            ...v,
            bool: true,
          });
        } else if (v.type === 'single') {
          const choice = v.choices.find((c) => c.name === variants[name]);
          variantDisplays.push({
            ...choice,
            choice: true,
          });
        }
      }
    }
  }

  let summary = '';
  for (const va of variantDisplays) {
    if (va.set) {
      summary += `${va.label} ${
        hidePrice ? '' : signDisplay(va.price)
      } / 飲料 ${hidePrice ? '' : signDisplay(va.discount)} `;
    } else {
      summary += `${va.label} ${hidePrice ? '' : signDisplay(va.price)} `;
    }
  }

  // if (summary) {
  //   summary = summary.slice(0, -1);
  // }

  return summary;
}

export function checkOrderType(orderType) {
  if (orderType) {
    if (orderType.type === 'eat-here' && orderType.table) {
      return true;
    } else if (orderType.type === 'take-away' && orderType.time) {
      return true;
    } else if (orderType.type === 'take-away' && orderType.time === null) {
      return true;
    }
  }
  return false;
}

export function getItemQtyInCart(cart, product) {
  const itemQtyInCart = (cart?.items || []).reduce((acc, item) => {
    if (item.product.id === product.id) {
      acc += item.qty;
    }
    return acc;
  }, 0);
  return itemQtyInCart;
}

export function getTotalItemQtyInCart(cart) {
  return (cart?.items || []).reduce((acc, item) => {
    acc += item.qty;
    return acc;
  }, 0);
}
